<template>
  <div class="bg box_bg">
        <div class="charts" @click="openDialog">
          <NoData v-if="noDataText" :text="noDataText" />
          <template v-else>
            <ja-init-chart :options="options" />
          </template>
        </div>
  </div>
</template>

<script>
import echarts from '/node_modules/echarts';
import { getworkChart, getOrderList } from '@/api'
import JaInitChart from '@/components/ja-init-chart/index'
import NoData from '@/components/noData'
import WPanelEntry from '@/views/dsWindow/IOC/WPanelEntry/index.vue'

export default {
  title: '物业维修分析',
  name: 'maintenanceAnalysis',
  components: {
    JaInitChart,
    NoData,
    WPanelEntry,

  },
  data() {
    return {
      options: {},
      noDataText:'',
      loading: true,
      info: {},
      key: [],
      value: [],
      value2: [],
      title: '物业维修分析',
      systemurl: '/home/workOrderManage/repairOrder',
      getOrderList,
      conditionData: [
        {
          input_type: 'date',
          type: 'daterange',
          value: 'time',
          startPlaceholder: '开始日期',
          endPlaceholder: '结束日期',
          valueFormat: 'yyyy-MM-dd'
        },
        {
          input_type: 'input',
          key: 'deviceCode',
          lable: '设备识别码',
          attribute: {
            placeholder: '设备识别码',
            size: 'small'
          }
        },
        { normalBtn: 'search' }
      ],
      columns: [
        { label: '工单类型', prop: 'repairTypeValue' },
        { label: '提单人', prop: 'submitEmployeeName' },
        { label: '工单状态', prop: 'statusValue' }
      ],
      dataInterval:null
    }
  },
  created() {
    // if (this.$store.state.token) {
    //   this.noDataText = ''
    this.startDataInterval()
    // } else {
    //   this.noDataText = '-- 请求数据缺少token --'
    //   this.loading = false
    // }
  },
  beforeDestroy() {
    if (this.dataInterval) {
      clearInterval(this.dataInterval);
    }// 在组件销毁前清除间隔事件
  },
  methods: {
    startDataInterval(){
      this.init()
      this.dataInterval = setInterval(() => {
            this.init()
      }, this.TIMER_INTERVAL);
    },
    async init() {
      await getworkChart().then((res) => {
        this.info = res.data
        this.loading = false
        for (let i = 0; i < this.info.months.length; i++) {
          if(this.info.months[i] < 10){
            this.key[i] = this.info.months[i].slice(1, 2) + '月'
          }else{
             this.key[i] = this.info.months[i].slice(0, 2) + '月'
          }
          this.value[i] = this.info.numbers[i]
        }
      })
      this.loading = false
      this.options = {
        tooltip: {
          show: true,
          type: 'item',
          position: function (point, params, dom, rect, size) {
            return [point[0] - 30, point[1] - 70] //返回x、y（横向、纵向）两个点的位置
          },
          backgroundColor: 'transparent',
          formatter: function (params) {
            if (params.seriesName == '维修次数') {
              var str = `<div style = "padding-top: 10px;font-size: 16px;background-image:url(${require('@/assets/images/screen/echarts_popup.png')});background-size: 100% 100%;width: 123px;height: 58px;">
            <div style = "width: 70%;margin: 0 auto;">${params.name}<br/> ${
                params.seriesName
              }: ${params.data}</div></div>`
              return str
            } else {
              return null
            }
          }
        },
        graphic: {
            elements: [
                {
                    type: 'text',
                    left: '5',
                    top: '23',
                    style: {
                        text: '单位：次数',
                        fontSize: 13,
                        fill: '#818a98',
                    }
                }
            ]
        },
        grid: {
          left: '1%',
          right: '5%',
          bottom: '1%',
          top: '15%',
          height: '75%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'value',
            axisTick: {
              show: false
            },
            axisLine: {
              lineStyle: {
                color: '#00487D',
                width: 0
              }
            },
            splitLine: {
              lineStyle: {
                color: '#999', // 分隔线的颜色
                width: 0 // 分隔线的宽度
              }
            },
            axisLabel: {
              show: false
            }
          }
        ],
        yAxis: [
          {
            type: 'category',
            data: this.key,
            axisLabel: {
              color: '#818a98',
              fontSize: 12
            },
            nameTextStyle: {
              color: '#fff',
              fontSize: 12
            },
            axisLine: {
              show: false // 隐藏 y 轴轴线
            },
            axisTick: {
              show: false // 隐藏 y 轴刻度线
            },
            splitLine: {
              lineStyle: {
                color: '#999', // 分隔线的颜色
                width: 0 // 分隔线的宽度
              }
            },
            inverse: true 
          }
        ],
        series: [
          {
            name: '维修次数',
            showBackground: true,
            type: 'bar',
            barWidth: 10,
            tooltip: {
              valueFormatter: function (value) {
                return value
              }
            },
            data: this.value,
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 2, 0, [
                { offset: 0.1, color: 'rgba(0,134,255,0.4)' }, // 尾部颜色较浅
                { offset: 0.7, color: 'rgb(6,200,137)' } // 尾部颜色较深
              ])
            },
            label: {
              normal: {
                show: true,
                position: 'insideBottomRight',
                distance: 5,
                offset: [10, 0],
                color: '#fff',
                fontSize: 12,
                padding: [5, 0, 6, 15],
                formatter: [
                    '{a|{c}}',
                    '{d|▼}',
                ].join(''),
                rich: {
                    d: {
                        color: '#FF7635',
                        padding:[0,0,0,6],
                        fontSize:14
                    },
                    a: {
                        color: '#93D4F6',
                        align: 'center',
                        fontSize:14
                    }
                }
                
              },
            }
          },
          {
              type: 'lines',
              coordinateSystem: 'cartesian2d',
              showBackground: true,
              data: this.value.map((item, index) => {
                return {
                  coords: [
                    [0, index],
                    [item>4?item - 4:0, index]
                  ]
                };
              }),
              effect: {
                show: true,
                period: 1.5,
                // trailLength: 0.5, //控制拖尾长度
                symbolSize: 14,
                symbol:'triangle',
                loop: true,
                color: '#0edfeb' //流光颜色
              },
              lineStyle: {
                width: 0,
                opacity: 0.6,
                curveness: 0
              },
              // z: 99
          }
        ]
      }
    },
    openDialog() {
      this.$refs.dialog.showDialog()
    }
  },
  watch: {
    // '$store.state.token': function () {
    //   if (this.$store.state.token) {
    //     this.noDataText = ''
    //     this.init()
    //   } else {
    //     this.noDataText = '-- 请求数据缺少token --'
    //     this.loading = false
    //   }
    // }
  }
}
</script>
<style lang="scss" scoped>
.charts {
  width: 100%;
  height: 100%;
  display: inline-block;
}
.box_bg {
  // background-image: url('~@/assets/images/screen/office_box2.png');
}
</style>

