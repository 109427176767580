<template>
  <div class="bg box_bg">
    <div class="chartbtn">
      <div :class="chartIndex == index ? 'selected' : ''" @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave" @click="checkChart(index)" v-for="(item,index) in chartBtn" :key="index">{{item.title}}</div>
    </div>

        <div class="charts" @click="openDialog">
        <NoData v-if="noDataText" :text="noDataText" />
        
            <template v-else>
              <ja-init-chart :options="options"/>
            </template>
        </div>
  </div>
</template>

<script>
import { waterAndElectricity } from '@/api'
import echarts from '/node_modules/echarts';
import { electricityPage,waterPage } from '@/api'
import JaInitChart from '@/components/ja-init-chart/index'
import NoData from '@/components/noData'
import WPanelEntry from '@/views/dsWindow/IOC/WPanelEntry/index.vue'
import chartcar from '@/assets/gd/ds_chartcarbg.png'
export default {
  title: '时段用量统计',
  name: 'energyConsumptionThan',
  components: {
    JaInitChart,
    NoData,
    WPanelEntry,
  },
  data() {
    return {
      chartcar,//背景图
      options: {},
      loading: true,
      waterList: {},
      electricList: {},
      waterTime: ["3月","4月","5月","6月","7月","8月"],
      waterNum: [6396,4396,6666,5700,8330,7100],//水数据
      electricNum: [6396,4396,6666,5700,8330,7100],//电数据
      // gasNum:[31,52,31,28,15,13],//气数据
      echartData:[6396,4396,6666,5700,8330,7100],
      chartIndex:0,
      chartBtn:[
        {title:'水'},
        {title:'电'},
        // {title:'气'}
      ],
      noDataText:'',
      electricityPage,//电抄表接口
      waterPage,//水抄表接口
      conditionData: [
        {
          input_type: 'date',
          type: 'daterange',
          value: 'time',
          startPlaceholder: '开始日期',
          endPlaceholder: '结束日期',
          valueFormat: 'yyyy-MM-dd'
        },
        {
          input_type: 'input',
          key: 'deviceCode',
          lable: '设备识别码',
          attribute: {
            placeholder: '设备识别码',
            size: 'small'
          }
        },
        { normalBtn: 'search' }
      ],
      columns: [
        { label: '设备名称', prop: 'deviceName' },
        { label: '位置', prop: 'position' },
        { label: '本期用量', prop: 'currentReading' }
      ],
      title:'时段水电走势',
      systemurl:'/home/energyModule/waterMeterReading',
      tablename:'水抄表',
      dataInterval:null,
      timer:null
    }
  },
  computed:{
    maxData(){
      let max = Math.max(...this.echartData);
      max = Math.ceil(max / 10) * 11;
      let arr = []
      this.echartData.forEach(item => {
        arr.push(max)
      })
      return arr
    }
  },
  created() {
    // if (this.$store.state.token) {
    //   this.noDataText = ''
      this.startDataInterval()
    // } else {
    //   this.noDataText = '-- 请求数据缺少token --'
    //   this.loading = false
    // }
    this.carousel()
  },
  beforeDestroy() {
    if (this.dataInterval) {
      clearInterval(this.dataInterval);
    }// 在组件销毁前清除间隔事件
    clearInterval(this.timer)
  },
  methods: {
    startDataInterval(){
      this.init()
      this.dataInterval = setInterval(() => {
            this.init()
      }, this.TIMER_INTERVAL);
    },
    async init() {
      // 获取时段用量统计数据
      // const {data} = await waterAndElectricity()
      const data ={
    "months": [
        "2023-11",
        "2023-10",
        "2023-09",
        "2023-08",
        "2023-07",
        "2023-06"
    ],
    "waterData": [
        "39511.0",
        "19503.0",
        "0",
        "0",
        "0",
        "0"
    ],
    "electricalData": [
        "11128.240000000085",
        "6553.0500000000375",
        "0",
        "0",
        "0",
        "0"
    ]
}
      this.waterTime = data.months.reverse() || []
      this.waterNum = data.waterData.reverse() || []
      this.waterNum = this.waterNum.map(item => {
       return item = Math.abs(parseInt(item))
      })
      this.electricNum = data.electricalData.reverse() || []
      this.electricNum = this.electricNum.map(item => {
        return item = Math.abs(parseInt(item))
      })
      this.echartData = this.waterNum
      this.setOptions()
      this.loading = false
    },
    setOptions(){
      this.options = {
        tooltip: {
          show: true,
          type: 'item',
          backgroundColor: 'transparent',
          formatter: (params)=> {
            var str = `<div style = "padding-bottom: 10px;padding-top: 5px;font-size: 16px;background-image:url(${require('@/assets/images/screen/echarts_popup.png')});background-size: 100% 100%;width: 123px;height: 58px;">
            <div style = "width: 70%;margin: 0 auto;">${params.name}<br/> ${this.chartIndex == 0 ? '水' : '电'}: ${params.data}</div></div>`
            return str
          }
        },
        xAxis: {
          type: 'category',
          axisTick: {
            show: false
          },
          data: this.waterTime,
          splitLine: { show: false },
          splitArea: { show: false },
          axisLine: {
            lineStyle: {
              color: '#1A3E47',
            }
          },
          axisLabel: {
            color: '#fff',
            rotate:40,
            fontSize: 10
          }
        },
        yAxis: {
          axisTick: {
            show: true,
            lineStyle: {
              color:'#4A957F',
            }
          },
          axisLine: {
            show: false
          },
          axisLabel: {
            color: '#818a98',
            fontSize: 12,
            // inside: true,
          },
          splitLine: {
           show:false
          },
          max: this.maxData[0]
        },
        grid: {
          left: '1%',
          right: '5%',
          bottom: '1%',
          height: '80%',
          containLabel: true
        },
        series: [
          {
            name: '水',
            type: 'line',
            stack: 'one',
            smooth: true,
            data: this.echartData,
            itemStyle: {
              color: '#5DB6DD'
            },
            barWidth: 28,
            areaStyle: {
                normal: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                            offset: 0,
                            color: 'rgba(57,247,247,0.3)'
                        },
                        {
                            offset: 1,
                            color: 'rgba(0,179,244,0)'
                        }
                    ], false),
                    shadowColor: 'rgba(35,193,255, 0.9)',
                    shadowBlur: 20
                }
            },
          },
          {
      data: this.echartData,
      type: "line",
      smooth: true,
      lineStyle: {
        color: "#ee7c2f",
      },
      animationDelay: 50,
      animationDuration: 5000,
    },
    {
      data:this.echartData,
      type: "line",
      smooth: true,
      lineStyle: {
        color: "#49bfde",
      },
      animationDelay: 500,
      animationDuration: 5000,
    },
          {
        name: '占位',
        type: 'pictorialBar',
        data: this.electricNum,
        barWidth: '30%',
        barGap: '-100%',
        silent: true,
        symbol: 'image://'+ this.chartcar,
        symbolClip: true,
        symbolSize: [42, '100%'],
        data: this.maxData
    },
          // {
          //   name: '电',
          //   type: 'line',
          //   stack: 'one',
          //   smooth: true,
          //   data: this.electricNum,
          //   itemStyle: {
          //     color: '#2F97EE'
          //   },
          //   barWidth: 28
          // },
          // {
          //   name: '气',
          //   type: 'line',
          //   stack: 'one',
          //   smooth: true,
          //   data: this.electricNum,
          //   itemStyle: {
          //     color: '#2F97EE'
          //   },
          //   barWidth: 28
          // }
        ]
      }
    },
    checkChart(i){
      this.chartIndex = i
      const arr = [
        this.waterNum,
        this.electricNum,
        this.gasNum
      ]
      const config = [
        {systemurl:'/home/energyModule/waterMeterReading',tablename:'水抄表'},
        {systemurl:'/home/energyModule/electricMeterReading',tablename:'电抄表'},
      ]
      this.systemurl = config[i].systemurl
      this.tablename = config[i].tablename
      this.echartData = arr[i]
      this.setOptions()
    },
    openDialog() {
      if(this.chartIndex == 0){
        this.$refs.waterdialog.showDialog()
      }else{
        this.$refs.dialog.showDialog()
      }
    },
    //轮播水电
    carousel(){
      this.timer=setInterval(() => {
        this.chartIndex++
        if(this.chartIndex>=2){
          this.chartIndex=0
        }
        this.checkChart(this.chartIndex)
      }, 3000);
    },
    handleMouseEnter(){
      console.log('barIndex----------入', this.chartIndex)
      clearInterval(this.timer)
    },
    handleMouseLeave(){
      console.log('barIndex----------出', this.chartIndex)
      this.carousel()
    }
  },
  watch: {
    // '$store.state.token': function () {
    //   if (this.$store.state.token) {
    //     this.noDataText = ''
    //     this.init()
    //   } else {
    //     this.noDataText = '-- 请求数据缺少token --'
    //     this.loading = false
    //   }
    // }
  }
}
</script>
<style lang="scss" scoped>
.charts {
  width: 100%;
  height: 100%;
  display: inline-block;
}
.box_bg {
  // background-image: url('~@/assets/images/screen/human_bg.png');
  .chartbtn{
    position: absolute;
    top: 24px;
    right: 15px;
    display: flex;
    z-index: 20;
    div{
      width: 66px;
      height: 24px;
      border: none;
      color: #fff;
      font-size: 14px;
      line-height: 24px;
      text-align:center;
      background-image: url('~@/assets/gd/ds_chatbtn.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }

    div:hover{
      cursor: pointer;
      background-image: url('~@/assets/gd/ds_chatbtnx.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }

    div.selected{
      background-image: url('~@/assets/gd/ds_chatbtnx.png');
    }
  }
}
</style>
