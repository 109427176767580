<template>
  <div class="entry bg" >
    <el-row>
      <el-col span="8">
        <rental-vacancy-rate class="flow"></rental-vacancy-rate>
        <energy-consumption class="flow"></energy-consumption>
        <energyConsumptionThan class="flow"></energyConsumptionThan>
      </el-col>
      <el-col span="8">
        <MaintenanceAnalysis class="flow"></MaintenanceAnalysis>
       <div  class="flow" style="height: 300px;">
        
       </div>
        <MaintenanceAnalysis2 class="flow"></MaintenanceAnalysis2>
      </el-col>
      <el-col span="8">
         <NewvehicleAnalysis class="flow"></NewvehicleAnalysis>
         <NewvehicleAnalysis1 class="flow"></NewvehicleAnalysis1>
        <MaintenanceAnalysis1 class="flow"></MaintenanceAnalysis1>
      </el-col>
      <!-- <el-col  span="6">
        <NewvehicleAnalysis2 class="flow"></NewvehicleAnalysis2>
      </el-col> -->
    </el-row>
  </div>
</template>

<script>
import rentalVacancyRate from './components/rentalVacancyRate/index.vue'
import EnergyConsumption from './components/energyConsumption/index.vue'
import energyConsumptionThan from './components/energyConsumptionThan/index.vue'
import NewvehicleAnalysis from './components/vehicleAnalysis/vehicleAnalysis.vue'
import MaintenanceAnalysis from './components/maintenanceAnalysis/index.vue'
import MaintenanceAnalysis1 from './components/maintenanceAnalysis1/index.vue'
import MaintenanceAnalysis2 from './components/maintenanceAnalysis2/index.vue'
import NewvehicleAnalysis1 from './components/vehicleAnalysis1/vehicleAnalysis.vue'
import NewvehicleAnalysis2 from './components/vehicleAnalysis2/vehicleAnalysis.vue'
export default {
  title: '德舜大厦可视化管理平台',
  name: 'officeBuilding',
  components: {
    rentalVacancyRate,
    EnergyConsumption,
    energyConsumptionThan,
    NewvehicleAnalysis,
    MaintenanceAnalysis,
    MaintenanceAnalysis1,
    MaintenanceAnalysis2,
    NewvehicleAnalysis1,
    NewvehicleAnalysis2
  },
  data() {
    return {
     
    }
  },
  created () {
     this.$emit('loading')
  },
  methods: {
  
    
  }
}
</script>
<style lang="scss" scoped>
@import '~@/style/mixin.scss';

.bg {
  height: 100%;
  position: relative;
  overflow: hidden;
  // background: #062b73;
  .scene {
            width: 100%;
            height: 100%;
            transform: scale(1);
        }
}
.entry {
  width: 100%;
  position: relative;
     background-image: url('~@/assets/gdbgimg.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
  // height: 5vw;
  // 导航栏
  .top {
    height: 88px;
    width: 100%;
    position: absolute;
    background: url('~@/assets/gd/head.png');
    background-size: 100% 100%;
    top: 0;
    left: 0;
    z-index: 1;
  }
  .left {
    position: absolute;
    background: url('~@/assets/gd/left.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 90vh;
    width: 24%;
    left: 10px;
    top: 70px;
  }
  .right {
    position: absolute;
    background: url('~@/assets/gd/right.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 90vh;
    width: 24%;
    right: 10px;
    top: 70px;
  }
  .mid {
    width: 50;
    position: absolute;
    top: 90px;
    left: 50%;
    transform: translate(-50%, 0);
    display: flex;
    .m_left {
      > div {
        width: 366px;
        height: 134px;
      }
    }
    .m_right {
      display: flex;
      > div {
        margin-left: 20px;
        width: 176px;
        height: 100px;
      }
    }
  }
  .bottom {
    height: 38px;
    width:66.5%;
    position: absolute;
    background: url('~@/assets/gd/foot.png');
    background-size: 100% 100%;
    bottom: 0;
    left: 50%;
    transform: translate(-50%,0);
    z-index: 1;
  }
  .systembtn {
    width: 36%;
    position: absolute;
    bottom: 56px;
    left: 50%;
    transform: translate(-50%, 0);
    height: 85px;
  }
}
.flow{
  width: 400px;
}
/deep/ .charts {
  width: 400px !important;
  height: 300px !important;
  display: inline-block;
  margin-bottom:10px;
}
</style>
