<template>
  <div class="bg box_bg">
    <!-- <div class="header">
      <div class="headerleft">道闸通行趋势</div>
    </div> -->

    <div class="charts" @mousemove="mouseoverFun" @mouseout="mouseoutFun">
      <ja-init-chart class="jachart" ref="chart" :options="options" />
    </div>
  </div>
</template>

<script>
import JaInitChart from '@/components/ja-init-chart/index'
import chartcar from '@/assets/gd/ds_chartcarbg.png'
import { gatePassage } from '@/api'

export default {
  components: {
    JaInitChart
  },
  data() {
    return {
      options: {},
      tiem:'',
      count:0,
      dataInterval:null,
      timerColor:null
    }
  },
  mounted() {
    this.startDataInterval()
    this.setInit()
    this.changeColor()
  },
  beforeDestroy() {
    if (this.dataInterval) {
      clearInterval(this.dataInterval);
    } // 在组件销毁前清除间隔事件
    clearInterval(this.time)
    clearInterval(this.timerColor)
  },
  methods: {    //获取道闸趋势数据
    changeColor(){
          var arr = [0, 1];
          var i = 0;
          let chart=this.$refs.chart
          this.timerColor = setInterval(show, 2000);

          //变色;
          var colorList = ['#D8FF23', '#25e3b4'];
          var colorLists = ['#00A5FF', '#24d1fb'];
          let that=this
          function show() {
             chart.myChart.setOption({
                  series: [{
                      itemStyle: {
                        normal: {
                          color: colorList[i],//柱条的颜色
                          shadowColor: 'rgba(255,255, 255, 0.2)',//阴影颜色
                          shadowBlur: arr[i] * 6,//阴影的模糊大小
                        }
                      }
                  },
                  {
                      itemStyle: {
                        normal: {
                          color: colorLists[i],//柱条的颜色
                          shadowColor: 'rgba(255,255, 255, 0.2)',//阴影颜色
                          shadowBlur: arr[i] * 6,//阴影的模糊大小
                        }
                      }
                  },
                  ]
              });

              i = i + 1;
              if (i == arr.length) {
                  //clearInterval(t);
                  i = 0;
              }
              // that.$nextTick(()=>{
              //   chart.myChart.clear();
              
              // })
              // chart.drawInit()
          }

    },
    startDataInterval(){
      this.init()
      this.dataInterval = setInterval(() => {
            this.init()
      }, this.TIMER_INTERVAL);
    },
    async init() {
      this.loading = false
      // const {data} = await gatePassage()
     let data=[
    {
        "time": "12-12",
        "thePeriod": 571,
        "currentPeriod": 518,
        "monthOverMonth": -9.28
    },
    {
        "time": "12-11",
        "thePeriod": 424,
        "currentPeriod": 0,
        "monthOverMonth": -100
    },
    {
        "time": "12-10",
        "thePeriod": 369,
        "currentPeriod": 0,
        "monthOverMonth": -100
    },
    {
        "time": "12-09",
        "thePeriod": 172,
        "currentPeriod": 0,
        "monthOverMonth": -100
    },
    {
        "time": "12-08",
        "thePeriod": 49,
        "currentPeriod": 0,
        "monthOverMonth": -100
    },
    {
        "time": "12-07",
        "thePeriod": 462,
        "currentPeriod": 0,
        "monthOverMonth": -100
    },
    {
        "time": "12-06",
        "thePeriod": 480,
        "currentPeriod": 0,
        "monthOverMonth": -100
    }
]
     
     
      const date = []//时间
      const thePeriod = []//上期数据
      const currentPeriod = []//本期数据
      const totalPersonTimes = []//总人次
      const monthOverMonth = []//环比
      data.map(item=>{
        date.unshift(item.time)
        thePeriod.unshift(item.thePeriod)//上期数据
        currentPeriod.unshift(item.currentPeriod)//本期数据
        totalPersonTimes.unshift(item.totalPersonTimes)//总人次
        monthOverMonth.unshift(item.monthOverMonth)//环比
      })
      this.alarmList = data
      this.options = {
        tooltip: {
          type: 'item',
          trigger: 'axis',
          backgroundColor: 'transparent',
          formatter: function (params) {
            const number = params[4]?.data ? params[4]?.data : -0
            const stra = number < 0 ? '下降' : '增长'
            const data = stra+Math.abs(number)
            let str = `<div style = "font-size: 12px;background-image:url(${require('../../../../../../assets/images/gd/bubble@2x.png')});background-size: 100% 100%;width: 100px;height: 100px;">
            <div style = "width: 90%;background-color: rgba(12, 30, 67, 0.8);color:#ca9b37;margin: 0 auto;">${params[0].name}<br/> 总人次${currentPeriod[params[0].dataIndex]}人<br/> 上期人次${params[0].data}人<br/> 环比${data}%</div>
            </div>`
            return str
          },
          axisPointer: {
            // type: 'cross',  鼠标移动上去显示 虚刻度线
            crossStyle: {
              color: '#999'
            },
          },
        },
        grid: {  //调整统计图上下左右边距
          width: '74%',
          left: 'center',
          bottom: 60,
        },
        calculable: true,
        xAxis: [
          {
            type: 'category',
            data: date,
            axisPointer: {
              type: 'shadow'
            },
            axisLabel: {
              show: true,
              rotate:40,
              textStyle: {
                color: '#fff',
                fontSize: 12
              }
            },
            axisLine: {   // X轴线的颜色
              lineStyle: {
                color: '#1A3E47'
              }
            }
          }
        ],
        yAxis: [
          {
            splitLine: {   //控制刻度横线的显示
              show: false
            },
            type: 'value',
            // max: 20,
            min: 0,
            nameLocation: "center",
            nameGap: 35,
            nameRotate: 0,
            nameTextStyle: {
              fontSize: 16,
            },
            axisLabel: {
              formatter: (params)=>{
                return params
              },
              show: true,
              textStyle: {
                color: '#27d0d8',
                fontSize: 12
              }
            },
            axisLine: {  // Y轴线的颜色、和轴线的宽度
              lineStyle: {
                color: '#797979',
                width: 2
              }
            }
          },
          {
            nameL: '环比',
            splitLine: {   //控制刻度横线的显示
              show: false
            },
            type: 'value',
            position: 'rigth',
            nameLocation: "center",
            nameGap: 35,
            nameRotate: 0,
            nameTextStyle: {
              fontSize: 16,
            },
            axisLabel: {
              formatter: (params)=>{
                return params
              },
              show: true,
              textStyle: {
                color: '#27d0d8',
                fontSize: 12
              }
            },
            axisLine: {  // Y轴线的颜色、和轴线的宽度
              lineStyle: {
                color: '#797979',
                width: 2
              }
            }
          }
        ],
        series: [
          {
            name: '去年',
            type: 'bar',
            yAxisIndex: 0,
            data: thePeriod,
            animationDuration: 1800, //加上这个
            animationEasing: "quadraticOut", //加上这个
            showBackground: true,
            backgroundStyle: {
                color: 'rgba(21,136,209,0.1)',
                image: 'image://' + chartcar,
            },
            itemStyle: {   //双Y轴A柱的柱体颜色
              normal: {
                color: '#D8FF23'
              }
            }
          },
          {
            name: '今年',
            type: 'bar',
            yAxisIndex: 0,
            animationDuration: 1800, //加上这个
            animationEasing: "quadraticOut", //加上这个
            showBackground: true,
            backgroundStyle: {
                color: 'rgba(21,136,209,0.1)',
                image: 'image://' + chartcar,
            },
            data: currentPeriod,
            itemStyle: {   //双Y轴B柱的柱体颜色
              normal: {
                color: '#00A5FF'
              },
            }
          },
          {
            //分隔
            name:'今年',
            tooltip: ' ',
            type: 'pictorialBar',
            itemStyle: {
              color: '#295C71'
            },
            symbolRepeat: 'fixed',
            symbolMargin: 3,
            symbol: 'rect',
            symbolClip: true,
            symbolSize: [14, 2],
            symbolPosition: 'start',
            symbolOffset: [10, 0],
            data: currentPeriod,
            z: 10
          },
          {
            //分隔
            name: '去年',
            tooltip: ' ',
            type: 'pictorialBar',
            itemStyle: {
              color: '#295C71'
            },
            symbolRepeat: 'fixed',
            symbolMargin: 3,
            symbol: 'rect',
            symbolClip: true,
            symbolSize: [14, 2],
            symbolPosition: 'start',
            symbolOffset: [-10, 0],
            data: thePeriod,
            z: 10
          },
          {
            name: '环比',
            data: monthOverMonth,
            type: 'line',
            yAxisIndex: 1,
            axisLine: { 
              onZero : false,
             },
            itemStyle: {
              color: '#218c69'
            },
            smooth: true
          }
        ]
      }
    },
    parmasFun(myChart,params,methods){
      const pointInPixel = [params.offsetX, params.offsetY];
      //点击第几个柱子
      let index;
      if (myChart.containPixel('grid', pointInPixel)) {
        index = myChart.convertFromPixel({seriesIndex: 0}, [params.offsetX, params.offsetY])[0];
      }
      if (index !== undefined) {
        this.$emit(methods,this.alarmList[index])
      }
    },
    mouseoverFun(){
      clearInterval(this.time)
    },
    mouseoutFun(){
      const myChart = this.$echarts.init(this.$refs.chart.$refs.wrap) 
      this.setTime(myChart)
    },
    setInit(){
      const myChart = this.$echarts.init(this.$refs.chart.$refs.wrap) 
      myChart.getZr().on('click',(params) => {
        this.parmasFun(myChart,params,'clickOn')
      })
      this.setTime(myChart)
    },
    setTime(myChart){
      // 代码高亮
      this.time = setInterval(() => {
          this.count === this.alarmList.length ? this.count = 0 : ''
          // 展示提示框
          myChart.dispatchAction({
            type: 'showTip',
            seriesIndex: 0,
            dataIndex: this.count
          });
          this.count++
      },3000)
    }
  }
}
</script>

<style lang="scss" scoped>
:-webkit-full-screen .box_bg {
  // .charts {
  //   margin-top: 50px;
  // }

  ul {
    right: 65px !important;

    li {
      width: 220px !important;
    }
  }
}
.flowr{
  .header{
    margin-left: 25px!important;
  }
}
.box_bg {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  position: relative;

  
  .header {
    height: 26px;
    width: 50%;
    padding-left: 10px;
    line-height: inherit;
    display: flex;
    overflow: hidden;
    color: #fff;
    align-items: center;
    background-image: url('~@/assets/gd/ds_charttitle.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;

    .icons {
      width: 12px;
      height: 8px;
    }

    .headerleft {
      flex-shrink: 0;
      line-height: 28px;
      font-size: 18px;
      font-weight: 400;
      position: relative;
      padding: 0 3px;
      color: #ffffff;
    }
  }

  .charts {
    margin-top: 10px;
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;

    .jachart {
      z-index: 2;
      width: 100%;
      height: 90%;
    }

  }
}
</style>